<template>
  <div class="custom-node" @click.stop.prevent>
<!--    <el-popover popper-class="page-manager-popover" :ref="`popover-${node.attrs['data-info'].id}`" trigger="hover"-->
<!--                placement="right-start">-->
<!--      <div class="node flex-center" slot="reference">-->
<!--        <span class="text">{{node.attrs['data-info'].name}}</span>-->
<!--      </div>-->
      <div class="node flex-center">
        <span class="text">{{node.attrs['data-info'].name}}</span>
      </div>
<!--      <div class="flex-col">-->
<!--        <span @click.stop="showDetail()" class="pop-item">详情</span>-->
<!--&lt;!&ndash;        <span @click.stop="newKeyword()" class="pop-item">新增</span>&ndash;&gt;-->
<!--        <span @click.stop="delPage()" class="pop-item">删除</span>-->
<!--      </div>-->
<!--    </el-popover>-->

<!--    <add-consult-service-reply-dialog v-if="addDialog.show"-->
<!--        v-model:open="addDialog.show"-->
<!--        :data="addDialog.loading || !addDialog.show ? null : addDialog.data"-->
<!--        @refresh="onRefresh"-->
<!--    ></add-consult-service-reply-dialog>-->
  </div>
</template>

<script>
// import AddConsultServiceReplyDialog from '../AddConsultServiceReplyDialog'
export default {
  name: 'custom-node',
  // components: {AddConsultServiceReplyDialog},
  inject: ["getGraph", "getNode"],
  data() {
    return {
      // addDialog: {
      //   show: false,
      //   data: null,
      //   loading: false,
      // },
    }
  },
  computed: {
    node() {
      return this.getNode()
    }
  },
  methods: {
    delPage(e) {
      this.$confirm('是否将关键词【' + this.node.attrs['data-info'].name + '】移入回收站？').then(() => {
        this.$bus.emit('removeConsultKeyword', this.node.attrs['data-info'])
      }).catch(() => {
      })
    },
    // 添加
    // addConsultServiceReplyKeyword() {
    //   this.addDialog.data = {}
    //   this.addDialog.show = true
    // },
    newKeyword() {
      this.$bus.emit('openAddConsultKeyword', this.node.attrs['data-info'])
      // this.$store.commit('consultKeyword/setShowNewKeyword', true) // this.$store 不可用
      // 如果有，还需要传父级id
      // this.openAdd(0) // injection 也不可用了
      // this.addConsultServiceReplyKeyword()
    },
    // onRefresh() {
    //
    // },
    showDetail() {
      this.$bus.emit('showConsultKeyword', this.node.attrs['data-info'])
    }
  }
}
</script>

<style lang="scss" >
.node {
  /*min-width: 120px;*/
  min-height: 44px;

  .text {
    @include clamp(2);
    text-align: center;
  }
}

.page-manager-popover {
  padding: 0;
  margin-top: -7px;

  .pop-item {
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    color: #3a3a3a;
    cursor: pointer;

    &:hover {
      background-color: rgba(216, 216, 216, 0.3);
    }

    &[disabled] {
      color: #c0c4cc;
      cursor: not-allowed;

      &:hover {
        background-color: unset;
      }
    }
  }
}
</style>