<template>
  <div class="page">
    <list-layout
      ref="consultServiceReplyRef"
      :filter-form="form"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      :on-delete="removeListItem"
      :on-edit="editListItem"
    >
      <template #top>
        <div style="margin-bottom: 20px">
          <el-button
            type="primary"
            size="small"
            @click="addConsultServiceReplyKeyword"
            >新建关键词</el-button
          >
          <el-button size="small" @click="showRelations"
            >关键词关系图</el-button
          >
        </div>
      </template>
      <template #filter>
        <consult-service-reply-filter
          :filter-form="form"
          :upload-filter="ok"
        ></consult-service-reply-filter>
      </template>
    </list-layout>

    <add-consult-service-reply-dialog
      v-model:open="addDialog.show"
      :data="addDialog.loading || !addDialog.show ? null : addDialog.data"
      :mode="addDialog.mode"
      @refresh="onRefresh"
    ></add-consult-service-reply-dialog>

    <el-dialog
      v-if="isShowRelations"
      title="关键词关系图"
      :visible.sync="isShowRelations"
      append-to-body
      fullscreen
    >
      <div :id="`x6-container-${randomId}`"></div>
      <div class="panel">
        <el-button
            type="primary"
            size="small"
            @click="addConsultServiceReplyKeyword"
        >新建关键词</el-button
        >
        <el-button-group style="margin-left: 20px">
          <el-button @click="zoomOut" size="small">
            <i class="el-icon-plus"></i>
          </el-button>
          <el-button @click="alignCenter" size="small">
            <i class="el-icon-aim"></i>
          </el-button>
          <el-button @click="zoomIn" :disabled="scale <= 0.5" size="small">
            <i class="el-icon-minus"></i>
          </el-button>
        </el-button-group>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ConsultServiceReplyFilter from '../../../components/consult-service/reply/ListFilter'
import {
  deleteConsultServiceReplyItem,
  deleteConsultServiceReplyItemForever,
  fetchConsultServiceReplyItemDetail,
  fetchConsultServiceReplyList,
  getEdges,
  saveConsultServiceReplyItem,
} from '../../../api/consult-service-reply'
import ListLayout from '../../../../../base/layout/ListLayout'
import AddConsultServiceReplyDialog from '../../../components/consult-service/reply/AddConsultServiceReplyDialog'
import consultServiceReplyList from '../../../mixin/consultServiceReplyList'

import '@antv/x6-vue-shape' /* 渲染 Vue 节点 */
import { Graph } from '@antv/x6'
import { DagreLayout } from '@antv/layout' // umd模式下， const { GridLayout } = window.layout
import CustomNode from '../../../components/consult-service/reply/keyword/CustomNode'
import {randomString} from '../../../../../base/utils/tool'

export default {
  components: {
    CustomNode,
    AddConsultServiceReplyDialog,
    ListLayout,
    ConsultServiceReplyFilter,
  },
  mixins: [consultServiceReplyList],
  // provide() {
  //   return {
  //     openAdd: () => {
  //       this.addConsultServiceReplyKeyword()
  //     }
  //   }
  // },
  data() {
    return {
      tabArr: [
        { label: '全部', name: 'normal' },
        { label: '回收站', name: 'deleted' },
      ],
      isShowRelations: false,
      scale: 1,
      randomId: randomString(9),
    }
  },
  // computed: {
  //   showNewKeyword() {
  //     return this.$store.state.consultKeyword.showNewKeyword;
  //   }
  // },
  watch: {
    // showNewKeyword(newValue, oldValue) {
    //   if (newValue) {
    //     // TODO: 上级id
    //     this.addConsultServiceReplyKeyword()
    //   }
    // },
    // 'addDialog.show'(val) {
    //   if (!val) {
    //     this.$store.commit('consultKeyword/setShowNewKeyword', false)
    //   }
    // },
    isShowRelations(val) {
      if (!val) {
        this.destroyX6()
      }
    }
  },
  mounted() {
    let that = this
    this.$bus.on('openAddConsultKeyword', (data) => {
      console.log('on bus ',data)
      that.addConsultServiceReplyKeyword()
    })
    this.$bus.on('showConsultKeyword', (data) => {
      console.log('on bus ',data)
      that.editListItem(data, 'drawer')
    })
    this.$bus.on('removeConsultKeyword', (data) => {
      console.log('on bus ',data)
      that.$refs.consultServiceReplyRef.handleDelete(data.id, 'normal', () => {
        if (that.isShowRelations) {
          that.destroyX6()
          that.showRelations()
        }
      })
    })
  },
  beforeDestroyed() {
    this.$bus.off('openAddConsultKeyword')
    this.$bus.off('showConsultKeyword')
    this.$bus.off('removeConsultKeyword')
  },
  methods: {
    // 数据删除
    removeListItem(idList, forever = false) {
      return forever
        ? deleteConsultServiceReplyItemForever({ ids: idList })
        : deleteConsultServiceReplyItem({ ids: idList })
    },
    /**
     * 列表编辑
     * @param{string} mode  弹窗还是抽屉显示
     */
    editListItem(row, mode = 'dialog') {
      this.addDialog.loading = true

      fetchConsultServiceReplyItemDetail({
        id: row.id,
      })
        .then(({ data }) => {
          this.addDialog.data = data
        })
        .catch(() => {})
        .finally(() => {
          this.addDialog.loading = false
        })

        this.addDialog.show = true
        this.addDialog.mode = mode
    },
    /**
     * @deprecated
     * 不需要了，在弹窗里面保存好了
     *
     * 执行保存接口
     */
    doSave() {
      saveConsultServiceReplyItem()
        .then(({ code, msg }) => {
          if (code) this.$message.success(msg)
          else this.$message.error(msg)
        })
        .catch(() => {})
    },
    // 新增、编辑保存成功后刷新
    onRefresh() {
      this.$refs.consultServiceReplyRef?.getList({ page: 1 })

      if (this.isShowRelations) {
        this.destroyX6()
        this.showRelations()
      }
    },
    showRelations() {
      this.isShowRelations = true

      getEdges()
        .then(({ data }) => {
          data.nodes.forEach((nd) => {
            nd.size = {
              width: 160,
              height: 44,
            }

            nd.shape = 'custom-node'

            nd.attrs = {
              'data-info': { id: nd.id, name: nd.name },
              body: {
                rx: 6,
                ry: 6,
                stroke: '#5F95FF',
                fill: '#EFF4FF',
                strokeWidth: 1,
              },
              // label: {
              //   text: nd.name,
              //   fontSize: 12,
              //   fill: '#262626',
              // },
            }
          })

          data.edges.forEach((edg) => {
            edg.attrs = {
              line: {
                stroke: '#A2B1C3',
                strokeWidth: 2,
              },
            }
            edg.connector = { name: 'smooth' }
          })

          // 宽度：
          var w =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth

          // 高度：
          var h =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight

          // https://x6.antv.vision/zh/docs/tutorial/basic/graph
          const graph = new Graph({
            container: document.getElementById('x6-container-' + this.randomId),
            width: w - 40,
            height: h - 120,
            // scroller: true,
            panning: true,
          })

          /* 背景色 */
          graph.drawBackground({
            color: 'rgba(246,246,248,.5)'
          })

          try {
            if (!this._x6_node_registered) {
              Graph.registerNode('custom-node', {
                inherit: 'vue-shape',
                // x: 200,
                // y: 150,
                // width: 150,
                // height: 100,
                component: CustomNode,
              })
              this._x6_node_registered = true
            }
          } catch(err) {
            console.log(err) // 重复注册了
          }

          const model = data

          const dagreLayout = new DagreLayout({
            type: 'dagre',
            rankdir: 'LR',
            align: 'UL',
            ranksep: 30,
            nodesep: 15,
            controlPoints: true,
          })

          const newModel = dagreLayout.layout(model)

          // const layoutData = gridLayout.layout(originData);
          // newModel.nodes.forEach(node => {
          //   node.x -= node.size.width / 2;
          //   node.y -= node.size.height / 2;
          // })

          graph.fromJSON(newModel)

          this.graph = graph

          graph.on('edge:click', ({ e, x, y, edge, view }) => {})

          // 点击事件
          graph.on('node:click', ({ node }) => {
            this.reset()
            node.attr('body/stroke', 'red') // 点击的元素改变边框颜色
            this.editListItem(node.attrs['data-info'], 'drawer')
          })

          graph.on('blank:click', () => {
            this.reset() // 点击空白处还原
          })
        })
        .catch(() => {})
    },
    reset() {
      const graph = this.graph
      const nodes = graph.getNodes()
      const edges = graph.getEdges()

      nodes.forEach((node) => {
        node.attr('body/stroke', '#5F95FF')
      })
    },
    destroyX6() {
      if (this.graph) {
        this.graph.dispose()
        this.graph = null
        console.log('graph disposed...')
      }
    },
    zoomIn() {
      this.graph.zoom(-0.5)
      this.scale -= 0.5
    },
    zoomOut() {
      this.graph.zoom(0.5)
      this.scale += 0.5
    },
    alignCenter() {
      this.graph.centerContent()
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  right: 60px;
  top: 110px;
}
</style>
